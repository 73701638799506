<template>
  <div>
    <div class="businessAccountDetail" v-if="accountLists.length">
      <h1>{{$t('project.other_system_business_accounts')}}</h1>
      <table class="tableList" cellpadding="0" >
        <tr class="th">
          <th>{{ $t("order.table.serial") }}</th>
          <!-- 序号 -->
          <th >{{ $t("project.business_account") }}</th>
          <!-- 业务账号 -->
          <th>{{ $t("project.default_Pwd") }}</th>
          <!-- 默认密码 -->
        </tr>
        <tr v-for="(item, index) in accountLists" :key="index">
          <td class="td">{{ index+1 }}</td>
          <td class="td">{{ item.account }}</td>
          <td class="td">{{ item.password }}</td>
        </tr>
      </table>

      <div class="fz14">{{$t('project.other_system_address')}}:<a href="http://api.njsc365.com/index.php/seller/" target="_blank" class="link">http://api.njsc365.com/index.php/seller/</a></div>
    </div>
    <a-empty  style="padding: 60px 0" v-else
              :image="require('@/assets/image/businessAccountEmpty.png')"
              :image-style="{ height: '145px' }">
      <template #description>
        <span class="emptyTip">
          {{$t('project.empty_tip')}}
        </span>
      </template>
    </a-empty>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import {getCurrentInstance, reactive, toRefs, ref, computed, watch} from "vue";
import { useStore } from "vuex";

export default {
  name: "businessAccountDetail",
  components: {
  },
  props: {
    data: {
      type: Array, // 类型检查  对象
    },
  },
  setup(props) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const state = reactive({
      accountLists: props.data,
    });
    const platformConfig = computed(() => store.getters.platformConfig);
    watch(
        () => props.data,
        (cb) => {
          if(cb && Array.isArray(cb) && cb.length){
            state.accountLists =cb
          }
        },
        { immediate: true }
    );
    return {
      ...toRefs(state),
      platformConfig,
    };
  },
};
</script>

<style lang="less" scoped>
.businessAccountDetail{
  .tableList{
    width: 100%;
    margin-bottom: 30px;
    tr{
      width: 100%;
      th{
        background: #FAFAFA;
        font-weight: 600;
      }
      td, th{
        padding: 10px 15px;
        text-align: left;
        text-align: left;
        word-wrap: break-word;
        word-break: break-all;
        border-bottom: 1px solid #EEE;
        color: #333333;
        &:nth-child(1){
          width: 160px;
        }
        &:nth-child(2){
          width: 295px;
        }
        &:nth-child(3){
          width: 295px;
        }

      }
    }
  }
}
.emptyTip{
  font-size: 14px;
  color: #999999;
}
h1{
  font-size: 14px;
  margin-bottom: 24px;
  font-weight: 600;
}
*{
  color: #333333;
}
.link{
  font-weight: normal;
  color: #2d68fa;
  &:hover{
    text-decoration: underline;
    color: #2d68fa;
  }
}
.fz14{
  font-size: 14px;}
</style>
